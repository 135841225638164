import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=82a59f3c&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82a59f3c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/builds/knockmedia/kbrax/src/components/Footer.vue').default})
