import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=62c4744a&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=62c4744a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c4744a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/builds/knockmedia/kbrax/src/components/Header.vue').default})
