
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {

  components: {
    FontAwesomeIcon
  },

  data() {
    return {
      nav: {
        nav1: {
          title: 'Company',
          to: '/company'
        },
        nav2: {
          title: 'Mindpure',
          to: '/mindpure'
        },
        nav3: {
          title: 'Services',
          to: '/services'
        },
        nav4: {
          title: 'Contact',
          to: '/contact'
        },
        ln: 'https://www.linkedin.com/company/kbrax/',
        tw: 'https://twitter.com/kbraxinc',
      },

      open: false
    };
  }
};
